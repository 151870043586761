import { createRef, useRef, useState } from 'react';
import './App.css';
import logo from './logo.png'
import {AnimatePresence, motion} from 'framer-motion'
import ReCAPTCHA from 'react-google-recaptcha';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import {Avatar, Button, CircularProgress, Icon, IconButton, Paper, TextField, Typography} from '@mui/material'
import {getAuth, RecaptchaVerifier, signInWithPhoneNumber} from 'firebase/auth';
import {doc, getFirestore, setDoc} from 'firebase/firestore';
import {getDownloadURL, getStorage,ref, uploadBytes, uploadString} from 'firebase/storage'
import { fireaBaseCapp } from './firebaseConfig';
import { AES } from 'crypto-js';
import { Stack } from '@mui/system';
import moment from 'moment';

function App() {
  const reCaptchaRef=useRef(null);
  const [step,setStep]=useState(0);
  const [img,setImg]=useState(null);
  const [imgUrl,setImgUrl]=useState(null);
  const [nex,setNext]=useState(false)
  const [msg,setMsg]=useState({
    msg:''
  })

  const auth=getAuth(fireaBaseCapp);

   window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container',{'size':"invisible",
  "callback":(re)=>{
    
  }
  },auth);


  //Student Details
  const [stdName,setStdName]=useState('');
  const [stdAdd,setStdAdd]=useState('');
  const [email,setEmail]=useState('');
  const [stdGDName,setGdName]=useState('');
  const [stdphno,setStdphno]=useState('');
  const [gdno,setGdNo]=useState('');
  const [aadhar,setAadhar]=useState('');
  const [stdSub,setStdSub]=useState('');
  const [stdClass,setStdClass]=useState('');
  const [stdDob,setStdDOB]=useState(moment('1995-02-08',"YYYY-MM-DD").format('YYYY-MM-DD'));
  const [dob,setDob]=useState('');
  //Erro Msg 
  const [nameErr,setNameErr]=useState({msg:'',st:false})
  const [addErr,setAddErr]=useState({msg:'',st:false})
  const [emailErr,setEmailErr]=useState({msg:'',st:false})
  const [stdGdnameErr,setGdNameErr]=useState({msg:'',st:false})
  const [stdPhErr,setStdPhErr]=useState({msg:'',st:false})
  const [gdnoErr,setGdNoErr]=useState({msg:'',st:false})
  const [aadharErr,setAadharErr]=useState({msg:'',st:false})
  const [subErr,setSubErr]=useState({msg:'',st:false})
  const [clsErr,setClsErr]=useState({msg:'',st:false})
  const [stddobErr,setStdDOBErr]=useState({msg:'',st:false});
  //OTP Error
  const[otpErr,setOTPErr]=useState({msg:'',st:false})
  const storage=getStorage(fireaBaseCapp);

  const [otp,setOTP]=useState('');

  function handleUpload(e){
    if(e.target.files[0]){
      setImgUrl(URL.createObjectURL(e.target.files[0]))
      setImg(e.target.files[0])
      setNext(true)
    }
  }
  const container = {
    hidden: { opacity: 0,x:-100 },
    show: {
      opacity: 1,
      x:0,
      transition: {
        delayChildren: 1,
        staggerDirection: -1
      }
    }
  }
  
  const item = {
    hidden: { x:-100},
    show: { x:0 }
  }

  const requestOTP=()=>{
    setStep(3)
    const appVerifier=window.recaptchaVerifier;
    signInWithPhoneNumber(auth,'+91'+stdphno,appVerifier).then((confirmRes)=>{
      window.confirmRes=confirmRes;
      console.log(confirmRes)
      
      setStep(2)
    })
  }
  const verifyOTP=()=>{
    const code=otp;

    setOTPErr({st:false,msg:''})
    window.confirmRes.confirm(code).then((res)=>{
      console.log(res.user)
      saveDatatoDatabase()
    }).catch((err)=>{
      setOTPErr({st:true,msg:'Invalid OTP'})
      console.log('bad otp')
    })
  }

  const submitCaptcha=async()=>{
    setMsg({msg:'Verifying and Uploading !'})
    const token=await reCaptchaRef.current.executeAsync()
    reCaptchaRef.current.reset();

    console.log({"token":token})
    
    const res=fetch(`https://core-technologies.com/demo/verify.php`,{method:"POST",headers: {
      "accept":"application/json"
  },
  body:JSON.stringify({
    "token":token
  })
}).then((res)=>(res.text()))
  .then((res)=>{
    res=JSON.parse(res)
    console.log(res.success)
    if(res.success){
      console.log(img.name)
      const uniq=String(Math.floor((Math.random() * 100000) + 1));
      const storageRef=ref(storage,'studentImages/'+uniq+img.name)
      uploadBytes(storageRef,img).then((snapshot)=>{
        console.log('Uploaded')
        getDownloadURL(ref(storage,storageRef)).then((downloadUrl)=>{
          console.log(downloadUrl)
          setImgUrl(downloadUrl)
          setStep(1)
        })
      })
      //setStep(1)
    }else{
      setMsg({msg:'Your device may be bot'})
    }
  })
  }

  //function for validation
  function ValidationData(){
    if(stdName===""){
      console.log('Empty StdName')
      setNameErr({msg:'Invalid Name',st:true})
      return
    }
    if(stdAdd==="" && stdAdd.length<5){
      console.log('Empty Address')
      setAddErr({msg:'Invalid Address',st:true})
      return
    }
    if(stdClass===""){
      console.log('Empty Class')
      setClsErr({msg:'Invalid Class',st:true})
      return
    }
    if(stdGDName===""){
      console.log('Empty GuardianName')
      setGdNameErr({msg:'Invalid Guardian Name',st:true})
      return
    }
    if(email===""){
      console.log('Empty email')
      setEmailErr({msg:'Invalid Email',st:true})
      return
    }
    if(stdphno==="" || stdphno.length!==10){
      console.log('Invalid Ph Number')
      setStdPhErr({msg:'Invalid Phone Number',st:true})
      return
    }
    if(gdno==="" || gdno.length!==10){
      console.log('Invalid Ph Number')
      setGdNoErr({msg:'Invalid Phone Number Name',st:true})
      return
    }
    if(aadhar==="" || aadhar.length!==12){
      console.log('Invalid Aadhar Number')
      setAadharErr({msg:'Invalid Aadhar ',st:true})
      return
    }
    if(stdSub===""){
      console.log('Invalid Subject')
      setSubErr({msg:'Invalid Class ',st:true})
      return
    }
    setNameErr({msg:'',st:false})
    setAddErr({msg:'',st:false})
    setClsErr({msg:'',st:false})
    setGdNameErr({msg:'',st:false})
    setEmailErr({msg:'',st:false})
    setStdPhErr({msg:'',st:false})
    setGdNoErr({msg:'',st:false})
    setAadharErr({msg:'',st:false})
    setClsErr({msg:'',st:false})
    console.log("All Validated")

    //saveDatatoDatabase()
    requestOTP()
    //setStep(2)
  }

  /*
  * Save details Functions Firebase
  *
  * 
  * */
  const db= getFirestore(fireaBaseCapp)
  async function saveDatatoDatabase(){
    
  var aadharEncrypt=AES.encrypt(aadhar,stdphno).toString();

    var stdChildren=stdName+stdphno;
    stdChildren=stdChildren.replace(/\s+/g,"");
  console.log({child:stdChildren})
    const data={
      "name":stdName,
      "class":stdClass,
      "address":stdAdd,
      "guardian":stdGDName,
      "stdph":stdphno,
      "std_guardian_no":gdno,
      "class_no":stdClass,
      "subjects":stdSub,
      "dob":dob,
      "std_aadhar":aadharEncrypt,
      "img":imgUrl
    }
    
    await setDoc(doc(db,"students",stdChildren),data).then((res)=>{
      console.log(data)
      setStep(4)
    })
 
    console.log(data);
    
  }
const getDOBtoString=(e)=>{
  var dat=moment(e,'YYYY-MM-DD')
  dat=dat.format('DD-MM-YYYY')
  console.log(dat)
  setDob(dat)
  setStdDOB(e)
}

  return (
    <div className="App">

      <Avatar sx={{width:45,height:45}} src={logo} alt="logo" />
        <Typography variant='h5' align='center' marginLeft={2}> Sunshine Instituition</Typography>
        <Typography variant='subtitle1' align='center' >Student Admission Form</Typography>

        <ReCAPTCHA className="overFloat" ref={reCaptchaRef} sitekey='6Leg9vkiAAAAAFc6qjjLGaC-FcSTJyigWnfl3u0N' size='invisible' badge='bottomright'  />
      <div className="flexBox">
      <motion.div initial={{x:200}} animate={{x:0}} transition={{type:'spring',duration:0.4}} className="frosty" >

        <AnimatePresence>
        {
          (()=>{
            if(step===0){
              return(
                  <motion.div key={'0'} initial={{x:-100,opacity:0}} animate={{x:0,opacity:1}} transition={{type:'spring',duration:0.2,delay:0.2}} style={{display:'flex',flexDirection:'column',height:'60vh',alignItems:'center',justifyItems:'center'}}>
                      <Typography>Upload Student Picture</Typography>
                      <div style={{marginBottom:'20px'}}>
                      <Button variant="contained" startIcon={<Icon>photo_camera</Icon>} component="label">
                        Upload
                        <input onChange={handleUpload} hidden accept="image/*" type="file" />
                      </Button>
                        </div>
                        <Avatar sx={{marginBottom:'10px',width:150,height:150}} src={imgUrl} />
                        <p>{msg.msg}</p>
                        {nex?(
                          <Button  onClick={()=>submitCaptcha()} variant='contained' endIcon={<Icon>arrow_forward</Icon>} >Fill Student Details</Button>
                        ):(
                          <p>Please Upload Image for Next Step</p>
                        )}
                        
                  </motion.div>
              )
            }else if(step===1){
              return(
                <motion.div key={'1'} initial="hidden" animate="show"  transition={{type:'spring',duration:1}} variants={container} >
                          
                <TextField error={nameErr.st} helperText={nameErr.msg} margin="normal" label="Student Name" fullWidth variants={item} onChange={e=>setStdName(e.target.value)} />
                <TextField error={addErr.st} helperText={addErr.msg} margin="normal" label="Student Address" fullWidth variants={item} onChange={e=>setStdAdd(e.target.value)} />
                <LocalizationProvider dateAdapter={AdapterMoment} >
                  <Stack sx={{marginBottom:2,marginTop:2}}  >
                  <MobileDatePicker label="Enter Date of Birth" value={stdDob} onChange={e=>getDOBtoString(e)} renderInput={(params) => <TextField sx={{width:'100%'}} helperText="" error={true} {...params} />} />
                  </Stack>
                </LocalizationProvider>
                <TextField error={emailErr.st} helperText={emailErr.msg} margin="normal" label="Student E-Mail" fullWidth variants={item} onChange={e=>setEmail(e.target.value)} />
                <TextField error={stdGdnameErr.st} helperText={stdGdnameErr.msg} margin="normal" label="Student Guardian Name" fullWidth variants={item} onChange={e=>setGdName(e.target.value)} />
                <TextField error={stdPhErr.st} helperText={stdPhErr.msg} margin="normal" label="Student Phone Number" fullWidth variants={item} onChange={e=>setStdphno(e.target.value)} />
                <TextField error={gdnoErr.st} helperText={gdnoErr.msg} margin="normal" label="Guardian Phone Number" fullWidth variants={item} onChange={e=>setGdNo(e.target.value)} />
                <TextField error={aadharErr.st}  helperText={aadharErr.msg} margin="normal" label="AADHAR Number / ID Number" fullWidth variants={item} onChange={e=>setAadhar(e.target.value)} />
                <TextField error={subErr.st} helperText={subErr.msg} margin="normal" label="Student Subjects" fullWidth  variants={item} onChange={e=>setStdSub(e.target.value)} />
                <TextField error={clsErr.st} helperText={clsErr.msg} margin="normal" label="Student Class" fullWidth  variants={item} onChange={e=>setStdClass(e.target.value)} />

              <Button variant='contained' endIcon={<Icon>arrow_forward</Icon>} onClick={()=>ValidationData()} >Confirm Details</Button>
                  
              </motion.div>
              )
            }else if(step===2){
              //OTP Page
              return(
                <motion.div key={'2'} initial={{x:-100,opacity:0}} animate={{x:0,opacity:1}} transition={{type:'spring',duration:0.5,delay:0.2}}  style={{display:'flex',flexDirection:'column',height:'60vh',alignItems:'center',justifyItems:'center'}}>
                  <Typography variant='subtitle1'>OTP Sent to  +91{stdphno}</Typography>
                  <TextField error={otpErr.st} helperText={otpErr.msg} onChange={e=>setOTP(e.target.value)} inputProps={{inputMode:'numeric'}} margin="normal" label="Enter OTP " fullWidth/>
                  <Button onClick={()=>verifyOTP()} startIcon={<Icon>save</Icon>} variant='contained'>Verify Mobile &amp; Save Details</Button>
                </motion.div>
              )

            }
            else if(step===3){
              return(
                <CircularProgress size={'30vw'} sx={{margin:15}} />
              )
            }else if(step===4){
              //Success Full Page
                return(
                  <div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}} >
                    <Typography sx={{color:'darkgreen'}} variant='h5' marginBottom={2}>Registration Successfull</Typography>
                    <Icon  sx={{ fontSize: 60,color:'green' }}>check</Icon>
                    <Avatar sx={{width:150,height:150,margin:5}}  src={imgUrl} />
                    <Typography >Name: {stdName}</Typography>
                    <Typography >Guardian Name: {stdGDName}</Typography>
                    <Typography >Class: {stdClass}</Typography>
                    <Typography >Subject(s): {stdSub}</Typography>
                    <Typography >Student Phone Number : {stdphno}</Typography>
                    <Typography sx={{color:'darkred',marginTop:2}}>Class Timing will be shared later by Principal</Typography>
                  </div>
                )
            }
          })()
        }
        </AnimatePresence>
        </motion.div>
      </div>

    </div>
  );
}

export default App;
