// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDBAglh-xdBs5fsIiaXjyrKrAl27sTUFMM",
  authDomain: "sunshine-f1241.firebaseapp.com",
  projectId: "sunshine-f1241",
  storageBucket: "sunshine-f1241.appspot.com",
  messagingSenderId: "353888491488",
  appId: "1:353888491488:web:4952548953fceea7b43d85",
  measurementId: "G-RKDGETV82H"
};

// Initialize Firebase
export const fireaBaseCapp = initializeApp(firebaseConfig);
